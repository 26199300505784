import Hammer from 'hammerjs';
import { breakpoints, siteHandle } from '../config';
import { getPrev, getNext, getSlide } from '../utilities/slider';
import setHeight from '../utilities/equalHeight';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { programs: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    sliderHandle,
    programHandle,
    dotHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.slider = this.el.querySelector(sliderHandle);
    this.programs = this.el.querySelectorAll(programHandle);
    this.dots = this.el.querySelectorAll(dotHandle);
    // Remove Hammer default to allow for text selection
    delete Hammer.defaults.cssProps.userSelect;
    this.swipeListener = new Hammer(this.el);

    // Event handler functions
    this.handlePrev = e => {
      e.preventDefault();
      getPrev(this.programs);
      getPrev(this.dots);
    };
    this.handleNext = e => {
      e.preventDefault();
      getNext(this.programs);
      getNext(this.dots);
    };
    this.handleDots = [].slice.call(this.programs)
      .map((program, index) => e => {
        e.preventDefault();
        getSlide(this.programs, index);
        getSlide(this.dots, index);
      });
    this.handleResizeEnabled = () => this.resizeSpy(true);
    this.handleResizeDisabled = () => this.resizeSpy(false);

    // Add event listeners
    this.enable();

    // Triggle resizeSpy to set carousel
    this.resizeSpy(true);
  }

  enable() {
    this.swipeListener.on('swiperight', this.handlePrev);
    this.swipeListener.on('swipeleft', this.handleNext);

    for (let i = 0; i < this.programs.length; i++) {
      this.dots[i].addEventListener('click', this.handleDots[i]);
    }

    // Watch breakpoint on resize
    window.removeEventListener('resize', this.handleResizeDisabled);
    window.addEventListener('resize', this.handleResizeEnabled);
  }

  disable() {
    this.swipeListener.off('swiperight', this.handlePrev);
    this.swipeListener.off('swipeleft', this.handleNext);

    for (let i = 0; i < this.programs.length; i++) {
      this.dots[i].removeEventListener('click', this.handleDots[i]);
    }

    // Remove previous resize listener and add disabled listener
    window.removeEventListener('resize', this.handleResizeEnabled);
    window.addEventListener('resize', this.handleResizeDisabled);
  }

  resizeSpy(enabled) {
    setHeight(this.programs);

    if (enabled) {
      if (window.innerWidth > breakpoints.sm.max) this.disable();
    } else {
      if (window.innerWidth <= breakpoints.sm.max) this.enable();
    }
  }
}

