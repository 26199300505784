/**
 * Scroll spy
 */

import { siteHandle } from '../config';
import { addClass, removeClass } from './dom';

function focusSpies(spies, threshold) {
  for (let i = 0; i < spies.length; i++) {
    const y = spies[i].getBoundingClientRect().top;
    const h = window.innerHeight;

    if (y < h * threshold) {
      // removeClass(spies[i], 'in-view');
      removeClass(spies[i], 'out-view-bottom');
      addClass(spies[i], 'out-view-top');
    } else if (y < h * (1 - threshold)) {
      removeClass(spies[i], 'out-view-top');
      removeClass(spies[i], 'out-view-bottom');
      addClass(spies[i], 'in-view');
    } else {
      // removeClass(spies[i], 'in-view');
      removeClass(spies[i], 'out-view-top');
      addClass(spies[i], 'out-view-bottom');
    }
  }
}

/*
 * Check if shadow boxes are on page and spy if so
 * @return {void}
 */
export default function (threshold = 0) {
  // Get all shadow boxes
  const spies = document.querySelectorAll('.spy');

  if (spies.length > 0) {
    // If currently spying shadow boxes remove listener
    if (window[siteHandle].hasScrollSpy) {
      window.removeEventListener('scroll', window[siteHandle].scrollSpy);
    }

    // Set focus function on scroll for current shadow boxes
    window[siteHandle].scrollSpy = () => focusSpies(spies, threshold);
    window.addEventListener('scroll', window[siteHandle].scrollSpy);
    window[siteHandle].hasScrollSpy = true;
    window[siteHandle].scrollSpy();
  }
}

