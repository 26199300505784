import Hammer from 'hammerjs';
import { siteHandle } from '../config';
import { getPrev, getNext } from '../utilities/slider';
import setHeight from '../utilities/equalHeight';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { pastPerformers: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    performersContHandle,
    performerHandle,
    cardHandle,
    prevHandle,
    nextHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.prev = this.el.querySelector(prevHandle);
    this.next = this.el.querySelector(nextHandle);
    const performersCont = this.el.querySelector(performersContHandle);
    const performers = this.el.querySelectorAll(performerHandle);
    const cards = this.el.querySelectorAll(cardHandle);
    // Remove Hammer default to allow for text selection
    delete Hammer.defaults.cssProps.userSelect;
    this.swipeListener = new Hammer(performersCont);

    // Event handler functions
    this.handlePrev = e => {
      e.preventDefault();
      getPrev(performers);
    };
    this.handleNext = e => {
      e.preventDefault();
      getNext(performers);
    };
    this.handleResize = () => setHeight(cards);

    // Add event listeners
    this.enable();

    // Set initial height
    setHeight(cards);
  }

  enable() {
    this.swipeListener.on('swiperight', this.handlePrev);
    this.swipeListener.on('swipeleft', this.handleNext);
    this.prev.addEventListener('click', this.handlePrev);
    this.next.addEventListener('click', this.handleNext);
    window.addEventListener('resize', this.handleResize);
  }
}

