import { breakpoints, siteHandle } from '../config';
import { scrollTo } from '../utilities/smoothScroll';
import { hasClass, addClass, removeClass, toggleClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { anchorNav: {} };

const activeClass = 'is-active';
const fixedClass = 'is-fixed';
const pushedClass = 'is-pushed';
const pastClass = 'is-past';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    navHandle,
    navToggle,
    backHandle,
    anchorLinkHandle,
    anchorHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.nav = this.el.querySelector(navHandle);
    this.navToggle = this.el.querySelector(navToggle);
    this.back = this.el.querySelector(backHandle);
    this.anchorLinks = this.el.querySelectorAll(anchorLinkHandle);
    this.anchors = this.el.querySelectorAll(anchorHandle);
    this.count = this.anchorLinks.length;
    // Semi-hacky way to get nav
    this.mainNav = document.querySelector('.nav');

    // Event handler functions
    this.handleScroll = () => this.scrollSpy();
    this.handleToggle = e => {
      e.preventDefault();
      toggleClass(this.nav, activeClass);
    };
    this.handleBack = e => {
      removeClass(this.nav, activeClass);
      scrollTo(elId, 96, e);
    };
    this.handleAnchors = [].slice.call(this.anchorLinks)
      .map(anchorLink => e => {
        const offset = window.innerWidth < breakpoints.md.min
          ? this.navToggle.offsetHeight
          : 0;

        removeClass(this.nav, activeClass);
        scrollTo(anchorLink.hash.substr(1), offset, e);
      });

    // Add event listeners
    this.enable();

    // Triggle scrollSpy to load nav
    this.scrollSpy();
  }

  enable() {
    window.addEventListener('scroll', this.handleScroll);
    this.navToggle.addEventListener('click', this.handleToggle);
    this.back.addEventListener('click', this.handleBack);
    for (let i = 0; i < this.count; i++) {
      this.anchorLinks[i].addEventListener('click', this.handleAnchors[i]);
    }
  }

  scrollSpy() {
    // Add class based on position of nav
    if (window.pageYOffset > this.el.offsetTop + this.el.offsetHeight) {
      // Nav past anchor nav sections
      addClass(this.el, pastClass);
      removeClass(this.el, fixedClass);
      this.el.style.paddingTop = 0;
    } else if (window.pageYOffset > this.el.offsetTop) {
      // Nav in anchor nav sections
      addClass(this.el, fixedClass);
      removeClass(this.el, pastClass);
      if (window.innerWidth < breakpoints.md.min) {
        this.el.style.paddingTop = `${this.navToggle.offsetHeight}px`;
      }
    } else {
      // Nav below window top
      removeClass(this.el, fixedClass);
      removeClass(this.el, pastClass);
      this.el.style.paddingTop = 0;
    }

    // If small screen and main nav is fixed transform anchor nav
    if (
      window.innerWidth < breakpoints.md.min
      && hasClass(this.mainNav, fixedClass)
      && hasClass(this.el, fixedClass)
    ) {
      addClass(this.el, pushedClass);
    } else {
      removeClass(this.el, pushedClass);
    }

    // Check if anchor sections are in view and add appropriate active classes
    for (let i = 0; i < this.count; i++) {
      const anchorOnTop =
        this.anchors[i].offsetTop
        - this.nav.offsetHeight
        - window.pageYOffset < 0;
      const anchorNotPast =
        (this.anchors[i].offsetTop + this.anchors[i].offsetHeight)
        - this.nav.offsetHeight
        - window.pageYOffset > 0;

      if (anchorOnTop && anchorNotPast) {
        addClass(this.anchorLinks[i], activeClass);
      } else {
        removeClass(this.anchorLinks[i], activeClass);
      }
    }
  }
}

