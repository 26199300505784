export const siteHandle = 'toscomusic';

// Breakpoints from src/styles/utilities/_variables.scss
const screenSmMin = 768;
const screenMdMin = 992;
const screenLgMin = 1200;
export const breakpoints = {
  xs: {
    max: screenSmMin - 1,
  },
  sm: {
    min: screenSmMin,
    max: screenMdMin - 1,
  },
  md: {
    min: screenMdMin,
    max: screenLgMin - 1,
  },
  lg: {
    min: screenLgMin,
  },
};

