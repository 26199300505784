import { siteHandle } from '../config';
import { addClass, removeClass, closestEl } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { form: {} };

/**
 * Function to check validity of email string and phone numbers
 * http://stackoverflow.com/questions/46155/validate-email-address-in-javascript?page=1&tab=votes#tab-top
 * http://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
 * @param {string} email
 * @return {bool}
 */
/* eslint-disable max-len */
function isEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function isPhone(phone) {
  const re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  return re.test(phone);
}
/* eslint-enable max-len */

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    formId,
    fieldContHandle,
    newsletterSignupName,
  }) {
    // Elements and class variables
    this.form = document.getElementById(formId);
    this.fieldContHandle = fieldContHandle.slice(1);
    this.inputs = this.form.querySelectorAll('input, select, textarea');
    // Remove hidden and newsletter signup inputs
    this.inputs = [].slice.call(this.inputs)
      .filter(input => (
        input.name !== 'secondary_email'
        && input.getAttribute('type') !== 'hidden'
        && input.name !== newsletterSignupName
      ));

    // Event handler functions
    this.handleBlurs = this.inputs
      .map(input => () => this.validateInput(input));
    this.handleSubmit = e => this.submitForm(this.inputs, e);

    // Add event listeners
    this.enable();
  }

  enable() {
    for (let i = 0; i < this.inputs.length; i++) {
      this.inputs[i].addEventListener('blur', this.handleBlurs[i]);
    }
    this.form.addEventListener('submit', this.handleSubmit);
  }

  validateInput(el) {
    const cont = closestEl(el, this.fieldContHandle);
    const type = el.getAttribute('type');
    const required = el.getAttribute('required') !== null;
    let valid = required
      ? Boolean(el.value)
      : true;

    switch (type) {
      case 'email':
        valid = isEmail(el.value);
        break;
      case 'tel':
        valid = isPhone(el.value);
        break;
      case 'number':
        if (el.getAttribute('min')) {
          valid = parseInt(el.value, 10) >= parseInt(el.getAttribute('min'), 10);
        }
        if (el.getAttribute('max')) {
          valid = parseInt(el.value, 10) <= parseInt(el.getAttribute('max'), 10);
        }
        break;
      default:
        break;
    }

    if (!valid) {
      removeClass(cont, 'is-valid');
      addClass(cont, 'has-error');
    } else {
      removeClass(cont, 'has-error');
      addClass(cont, 'is-valid');
    }

    return valid;
  }

  submitForm(inputs, e) {
    e.preventDefault();

    let submit = true;

    for (let i = 0; i < inputs.length; i++) {
      if (!this.validateInput(inputs[i])) {
        submit = false;
        break;
      }
    }

    if (submit) this.form.submit();
  }
}

