import Hammer from 'hammerjs';
import { siteHandle } from '../config';
import { getPrev, getNext, getSlide } from '../utilities/slider';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { imageGallery: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    sliderHandle,
    imageHandle,
    dotHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.slider = this.el.querySelector(sliderHandle);
    this.images = this.el.querySelectorAll(imageHandle);
    this.dots = this.el.querySelectorAll(dotHandle);
    this.swipeListener = new Hammer(this.el);

    // Event handler functions
    this.handlePrev = e => {
      e.preventDefault();
      getPrev(this.images);
      getPrev(this.dots);
    };
    this.handleNext = e => {
      e.preventDefault();
      getNext(this.images);
      getNext(this.dots);
    };
    this.handleDots = [].slice.call(this.images)
      .map((image, index) => e => {
        e.preventDefault();
        getSlide(this.images, index);
        getSlide(this.dots, index);
      });

    // Add event listeners
    this.enable();
  }

  enable() {
    this.swipeListener.on('swiperight', this.handlePrev);
    this.swipeListener.on('swipeleft', this.handleNext);

    for (let i = 0; i < this.images.length; i++) {
      this.dots[i].addEventListener('click', this.handleDots[i]);
    }
  }
}

