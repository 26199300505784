/**
 * Main javascript
 */

import svg4everybody from 'svg4everybody';
import instantiateComponents from './utilities/components';
import { addClass } from './utilities/dom';

// Scan for global flags to populate class instances
document.addEventListener('DOMContentLoaded', () => {
  // SVG 4 Everybody polyfill
  svg4everybody();
  // Call constructor function for all components on page
  instantiateComponents();
  // Hide loading animation
  addClass(document.querySelector('.loader'), 'is-hidden');
});

