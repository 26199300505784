import { siteHandle } from '../config';
import { removeClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { shareButton: {} };

const activeClass = 'is-active';
const lockedClass = 'is-locked';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    closeHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.close = this.el.querySelector(closeHandle);

    // Event handler functions
    this.handleClose = e => {
      e.preventDefault();
      removeClass(this.el, activeClass);
      removeClass(document.body, lockedClass);
    };

    // Add event listeners
    this.enable();
  }

  enable() {
    this.close.addEventListener('click', this.handleClose);
  }
}

