import { siteHandle } from '../config';
import { toggleClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { shareButton: {} };

const activeClass = 'is-active';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    toggleHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.toggle = this.el.querySelector(toggleHandle);

    // Event handler functions
    this.handleToggle = () => toggleClass(this.el, activeClass);

    // Add event listeners
    this.enable();
  }

  enable() {
    this.toggle.addEventListener('click', this.handleToggle);
  }
}

