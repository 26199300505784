import { siteHandle } from '../config';
import { toggleClass } from '../utilities/dom';
import { getPrev, getNext } from '../utilities/slider';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { testimonials: {} };

const flippedClass = 'is-flipped';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    testimonialHandle,
    quoteHandle,
    sourceHandle,
    prevHandle,
    nextHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.quotes = this.el.querySelectorAll(quoteHandle);
    this.prev = this.el.querySelector(prevHandle);
    this.next = this.el.querySelector(nextHandle);
    const testimonials = this.el.querySelectorAll(testimonialHandle);
    const sources = this.el.querySelectorAll(sourceHandle);

    // Event handler functions
    this.handlePrev = e => {
      e.preventDefault();
      getPrev(testimonials);
      getPrev(sources);
      this.toggleQuotes();
    };
    this.handleNext = e => {
      e.preventDefault();
      getNext(testimonials);
      getNext(sources);
      this.toggleQuotes();
    };

    // Add event listeners
    this.enable();
  }

  enable() {
    this.prev.addEventListener('click', this.handlePrev);
    this.next.addEventListener('click', this.handleNext);
  }

  toggleQuotes() {
    for (let i = 0; i < this.quotes.length; i++) {
      toggleClass(this.quotes[i], flippedClass);
    }
  }
}

