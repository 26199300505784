import { siteHandle } from '../config';
import { addClass, removeClass, toggleClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { dropdown: {} };

const activeClass = 'is-active';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    toggleHandle,
    toggleTextHandle,
    linkHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.toggle = this.el.querySelector(toggleHandle);
    this.toggleText = this.el.querySelector(toggleTextHandle);
    this.links = this.el.querySelectorAll(linkHandle);

    // Event handler functions
    this.handleToggle = e => {
      e.preventDefault();
      toggleClass(this.el, activeClass);
    };
    this.handleLinks = [].slice.call(this.links)
      .map(link => e => {
        if (link.href === '#') e.preventDefault();

        toggleClass(this.el, activeClass);
        this.setToggleText(link);
      });

    // Add event listeners
    this.enable();
  }

  enable() {
    this.toggle.addEventListener('click', this.handleToggle);
    for (let i = 0; i < this.links.length; i++) {
      this.links[i].addEventListener('click', this.handleLinks[i]);
    }
  }

  setToggleText(link) {
    for (let i = 0; i < this.links.length; i++) {
      removeClass(this.links[i], activeClass);
    }
    addClass(link, activeClass);
    this.toggleText.textContent = link.textContent;
  }
}

