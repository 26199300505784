import { siteHandle } from '../config';
import { scrollTop } from '../utilities/smoothScroll';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { hero: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    anchorTag,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);

    // Event handler functions
    this.handleScroll = e => scrollTop(anchorTag, e);

    // Add event listeners
    this.enable();
  }

  enable() {
    this.el.addEventListener('click', this.handleScroll);
  }
}

