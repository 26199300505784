import { breakpoints, siteHandle } from '../config';
import { hasClass, addClass, removeClass, toggleClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { nav: {} };

const activeClass = 'is-active';
const lockedClass = 'is-locked';
const fixedClass = 'is-fixed';

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    toggleHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.toggle = this.el.querySelector(toggleHandle);
    this.currentPositionY = window.pageYOffset;

    // Event handler functions
    this.handleScroll = () => this.scrollSpy();
    this.handleToggle = e => {
      e.preventDefault();
      toggleClass(this.el, activeClass);
      toggleClass(document.body, lockedClass);
    };

    // Add event listeners
    this.enable();

    // Triggle scrollSpy to load nav
    this.scrollSpy();
  }

  enable() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.toggle.addEventListener('click', this.handleToggle);
  }

  /**
   * Function to check if window is scrolling down to hide nav
   */
  scrollSpy() {
    if (window.innerWidth >= breakpoints.md.min || hasClass(this.el, activeClass)) {
      return;
    }

    // If scroll is not past nav height or scrolling up, fix nav
    if (
      window.pageYOffset < this.el.offsetHeight
      || window.pageYOffset - this.currentPositionY < 0
    ) {
      addClass(this.el, fixedClass);
    } else {
      removeClass(this.el, fixedClass);
    }
    this.currentPositionY = window.pageYOffset;
  }
}

