/**
 * Utility methods for interacting with component classes
 */

import { siteHandle } from '../config';
import spy from './scrollSpy';
import AnchorNav from '../components/anchorNav';
import BackgroundVideo from '../components/backgroundVideo';
import CardDeck from '../components/cardDeck';
import Dropdown from '../components/dropdown';
import Form from '../components/form';
import Friends from '../components/friends';
import Hero from '../components/hero';
import ImageGallery from '../components/imageGallery';
import ImageHeading from '../components/imageHeading';
import LinkButton from '../components/linkButton';
import MapHeading from '../components/mapHeading';
import Members from '../components/members';
import MembersTabs from '../components/membersTabs';
import Modal from '../components/modal';
import Nav from '../components/nav';
import NewsListings from '../components/newsListings';
import PastPerformers from '../components/pastPerformers';
import PastPerformersListings from '../components/pastPerformersListings';
import Programs from '../components/programs';
import Resources from '../components/resources';
import ShareButton from '../components/shareButton';
import Testimonials from '../components/testimonials';

const classMap = {
  anchorNav: AnchorNav,
  backgroundVideo: BackgroundVideo,
  cardDeck: CardDeck,
  dropdown: Dropdown,
  form: Form,
  friends: Friends,
  hero: Hero,
  imageGallery: ImageGallery,
  imageHeading: ImageHeading,
  linkButton: LinkButton,
  mapHeading: MapHeading,
  members: Members,
  membersTabs: MembersTabs,
  modal: Modal,
  nav: Nav,
  newsListings: NewsListings,
  pastPerformers: PastPerformers,
  pastPerformersListings: PastPerformersListings,
  programs: Programs,
  resources: Resources,
  shareButton: ShareButton,
  testimonials: Testimonials,
};

/**
 * Scan for global flags to instantiate new component classes.
 * This is moved into a utilities file so that it can be called by both the
 * main javascript file as well as functions that make ajax calls for templates
 * that may contain new global flags.
 * @return {void}
 */
export default function () {
  const siteObj = window[siteHandle];
  const components = siteObj.components;

  for (let i = 0; i < components.length; i++) {
    if (!components[i].instance) {
      const Class = classMap[components[i].handle];
      components[i].instance = new Class(components[i]);
    }
  }

  spy();
}

