import { siteHandle } from '../config';
import setHeight from '../utilities/equalHeight';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { pastPerformersListings: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    performerHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    const els = this.el.querySelectorAll(performerHandle);

    // Event handler functions
    this.handleResize = () => setHeight(els);

    // Add event listeners
    this.enable();

    // Set initial height
    setHeight(els);
  }

  enable() {
    window.addEventListener('resize', this.handleResize);
  }
}

