/**
 * Equal height resizer
 */

/* eslint-disable no-param-reassign */
export default function (els) {
  let height = 0;

  // Find largest height
  for (let i = 0; i < els.length; i++) {
    // Reset heights before calculating
    els[i].style.height = 'inherit';
    // Select max height
    height = Math.max(height, els[i].offsetHeight);
  }

  // Set all elements to largest height
  for (let i = 0; i < els.length; i++) {
    els[i].style.height = `${height}px`;
  }
}
/* eslint-enable no-param-reassign */

