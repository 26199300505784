/**
 * Utility methods for DOM manipulation
 */

/**
 * Vanilla JS class addition and removal
 * http://jaketrent.com/post/addremove-classes-raw-javascript/
 * Allow for direct manipulation of parameter DOM elements
 * Allow for string concatenation to prevent regex escape errors with template strings
 */
/* eslint-disable no-param-reassign, prefer-template */
export function hasClass(el, className) {
  return el.classList
    ? el.classList.contains(className)
    : !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
  } else if (!hasClass(el, className)) {
    el.className += ' ' + className;
  }
}

export function removeClass(el, className) {
  if (el.classList) {
    el.classList.remove(className);
  } else if (hasClass(el, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    el.className = el.className.replace(reg, ' ');
  }
}

export function toggleClass(el, className) {
  if (hasClass(el, className)) {
    removeClass(el, className);
  } else {
    addClass(el, className);
  }
}

export function closestEl(el, className) {
  if (!el.parentElement) return null;

  return hasClass(el.parentElement, className)
    ? el.parentElement
    : closestEl(el.parentElement, className);
}
/* eslint-enable no-param-reassign, prefer-template */

