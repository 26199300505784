/**
 * Library for handling slider/caoursel functionality
 */

import { hasClass, addClass, removeClass } from './dom';

const activeClass = 'is-active';
const fromLeftClass = 'from-left';
const fromRightClass = 'from-right';

function clearClasses(slide) {
  removeClass(slide, fromLeftClass);
  removeClass(slide, fromRightClass);
  removeClass(slide, activeClass);
}

/*
 * Get previous slide or last slide if at 0
 * @param {array} slides
 * @return {void}
 */
export function getPrev(slides) {
  let prevSlide = null;

  for (let i = 0; i < slides.length; i++) {
    if (hasClass(slides[i], activeClass)) {
      prevSlide = slides[i - 1]
        ? slides[i - 1]
        : slides[slides.length - 1];

      clearClasses(slides[i]);
      break;
    }
  }

  addClass(prevSlide, fromLeftClass);
  addClass(prevSlide, activeClass);
}

/*
 * Get next slide or first slide if at last
 * @param {array} slides
 * @return {void}
 */
export function getNext(slides) {
  let nextSlide = null;

  for (let i = 0; i < slides.length; i++) {
    if (hasClass(slides[i], activeClass)) {
      nextSlide = slides[i + 1]
        ? slides[i + 1]
        : slides[0];

      clearClasses(slides[i]);
      break;
    }
  }

  addClass(nextSlide, fromRightClass);
  addClass(nextSlide, activeClass);
}

/*
 * Get slide by index
 * @param {array} slides
 * @param {int} index
 * @return {void}
 */
export function getSlide(slides, index) {
  let current = null;

  for (let i = 0; i < slides.length; i++) {
    if (hasClass(slides[i], activeClass)) {
      current = i;
      clearClasses(slides[i]);
      break;
    }
  }

  const fromClass = index < current
    ? fromLeftClass
    : fromRightClass;

  addClass(slides[index], fromClass);
  addClass(slides[index], activeClass);
}

