import { siteHandle } from '../config';
import { scrollBottom } from '../utilities/smoothScroll';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { hero: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    elClass,
    scrollHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.scrollEl = this.el.querySelector(scrollHandle);

    // Event handler functions
    this.handleScroll = e => scrollBottom(elClass, e);

    // Add event listeners
    this.enable();
  }

  enable() {
    this.scrollEl.addEventListener('click', this.handleScroll);
  }
}

