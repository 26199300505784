import { siteHandle } from '../config';
import isMobile from '../utilities/device';
import { addClass } from '../utilities/dom';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { hero: {} };

const activeClass = 'is-active';
const aspectRatio = 16 / 9;

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    videoHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.video = this.el.querySelector(videoHandle);

    if (isMobile()) {
      this.el.style.display = 'none';
      return;
    }

    // Event handler functions
    this.handleLoad = () => addClass(this.video, activeClass);
    this.handleResize = () => this.resizeVideo();

    // Add event listeners
    this.enable();

    // Set initial video size
    this.resizeVideo();
  }

  enable() {
    this.video.addEventListener('playing', this.handleLoad);
    window.addEventListener('resize', this.handleResize);
  }

  resizeVideo() {
    const elementRatio = this.el.offsetWidth / this.el.offsetHeight;
    if (elementRatio < aspectRatio) {
      this.video.style.width = 'auto';
      this.video.style.height = '100%';
    } else {
      this.video.style.width = '100%';
      this.video.style.height = 'auto';
    }
  }
}

