import GoogleMapsLoader from 'google-maps';
import { siteHandle } from '../config';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { mapHeading: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    mapId,
    address,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.mapEl = document.getElementById(mapId);

    this.loadMap(address);
  }

  loadMap(address) {
    // Load the Google Maps API
    GoogleMapsLoader.KEY = 'AIzaSyDbot6nyc90-cnTXwd581TVh1HM6O7RaSA';
    GoogleMapsLoader.load(google => {
      // Initialized map
      const map = new google.maps.Map(this.mapEl, {
        center: new google.maps.LatLng(0, 0),
        zoom: 15,
        mapTypeControl: false,
      });

      // Use geocoder to convert text address to lat long
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        // Return on error status
        if (status !== google.maps.GeocoderStatus.OK) return;

        // Add marker and center map for successful geocode
        if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
          const marker = new google.maps.Marker({
            position: results[0].geometry.location,
            title: address,
          });

          // Set marker on map and center
          marker.setMap(map);
          map.setCenter(results[0].geometry.location);
        }
      });
    });
  }
}

