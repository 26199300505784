import { siteHandle } from '../config';
import { getSlide } from '../utilities/slider';

// For testing we need to create the component object manually
if (!window[siteHandle]) window[siteHandle] = { friends: {} };

export default class {
  /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
  constructor({
    elId,
    tabHandle,
    tierHandle,
  }) {
    // Elements and class variables
    this.el = document.getElementById(elId);
    this.tabs = this.el.querySelectorAll(tabHandle);
    const tiers = this.el.querySelectorAll(tierHandle);

    // Event handler functions
    this.handleTabs = [].slice.call(this.tabs)
      .map((level, index) => e => {
        e.preventDefault();
        getSlide(this.tabs, index);
        getSlide(tiers, index);
      });

    // Add event listeners
    this.enable();
  }

  enable() {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].addEventListener('click', this.handleTabs[i]);
    }
  }
}

