/**
 * Library for handling smooth scrolling functionality
 */

import rAF from 'r-a-f';

function scroll(target) {
  // Logrithmic scroll rate (higher scrolls faster)
  const scrollRate = 0.15;
  // Current scroll position
  const current = window.pageYOffset;
  // Set flag for scroll direction
  const down = current < target;
  // Set step based on scroll rate
  const step = (Math.abs(current - target) * scrollRate) + 1;
  // Set next position based on scroll direction
  const next = down
    ? current + step
    : current - step;
  // Set past flag based on scroll direction
  const isPast = down
    ? next >= target
    : next <= target;
  // Set flag to check if at bottom of window for scrolling down
  const tolerance = 5;
  const atBottom = down
    ? (window.innerHeight + window.pageYOffset + tolerance) >= document.body.offsetHeight
    : false;

  // Scroll to next position
  window.scrollTo(0, next);

  if (!isPast && !atBottom) {
    rAF(() => scroll(target));
  } else {
    // Scroll down to hide header on mobile
    window.scrollTo(0, current + tolerance);
  }
}

/*
 * Scroll to element specified by id
 * @param {string} anchor - element id
 * @param {int} offset
 * @param {object} e - event
 * @return {void}
 */
export function scrollTo(anchor, offset, e) {
  e.preventDefault();
  // Get element to scroll
  const el = document.getElementById(anchor);
  // Get position of element
  const top = el.offsetTop - offset;

  rAF(() => scroll(top));

  // Update URL hash
  if (history.pushState) {
    history.pushState(null, null, `#${anchor}`);
  } else {
    window.location.hash = `#${anchor}`;
  }
}

/*
 * Scroll to top of element specified by selector
 * @param {string} selector
 * @param {object} e - event
 * @return {void}
 */
export function scrollTop(selector, e) {
  e.preventDefault();
  // Get element to scroll
  const el = document.querySelector(selector);
  // Get top position of element
  const top = el.offsetTop;

  rAF(() => scroll(top));
}

/*
 * Scroll to bottom of element specified by selector
 * @param {object} e - event
 * @return {void}
 */
export function scrollBottom(selector, e) {
  e.preventDefault();
  // Get element to scroll
  const el = document.querySelector(selector);
  // Get bottom position of element
  const bottom = el.offsetTop + el.offsetHeight;

  rAF(() => scroll(bottom));
}

